import { ToastContainer } from 'react-toastify';

import MainRoutes from '../routes/MainRoutes/MainRoutes';

import 'react-toastify/dist/ReactToastify.css';
import './App.styles.scss';

function App() {
    return (
        <div className="App">
            <>
                <ToastContainer
                    theme="dark"
                    autoClose={5000}
                    closeButton={true}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                />

                <MainRoutes />
            </>
        </div>
    );
}

export default App;
