import './Loader.styles.scss';

const Loader = ({ small }) => {
    return (
        <div className={`loader ${small ? 'loader--small' : ''}`} data-testid="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default Loader;
