import { toast } from 'react-toastify';

import { getNotificationsSettings } from '../data/notificationsSettings';

export const getAllTypeGroupsService = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/type-groups/all`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const paginateTypeGroupsService = async (page) => {
    try {
        const size = 10;

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/settings/type-groups?size=${size}&page=${page}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addTypeGroupService = async (typeGroup) => {
    const typeGroupNotificationId = 'type-group-add';
    toast.loading('Please wait...', { toastId: typeGroupNotificationId });

    try {
        const body = {
            name: typeGroup.name,
            description: typeGroup.description,
            is_strict: 1,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/type-groups`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const data = await response.json();

            toast.update(typeGroupNotificationId, {
                render: 'Type Group added successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(typeGroupNotificationId, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};

export const updateTypeGroupService = async (typeGroup, group_id) => {
    try {
        const body = {
            name: typeGroup.name,
            description: typeGroup.description,
            is_strict: 1,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/type-groups/${group_id}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const deleteTypeGroupService = async (group_id) => {
    const typeGroupNotificationId = 'type-group-delete';

    toast.loading('Please wait...', { toastId: typeGroupNotificationId });

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/type-groups/${group_id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = await response.json();

            toast.update(typeGroupNotificationId, {
                render: 'Type Group deleted successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(typeGroupNotificationId, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};

export const getAllTypesService = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/types/all`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const paginateTypesService = async (page, search = '') => {
    try {
        const size = 10;

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/settings/types?search=${search}&size=${size}&page=${page}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addTypeService = async (type) => {
    const typeNotificationId = 'type-add';

    toast.loading('Please wait...', { toastId: typeNotificationId });

    try {
        const body = {
            name: type.name,
            description: type.description,
            type_group_id: type.type_group_id,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/types`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const data = await response.json();

            toast.update(typeNotificationId, {
                render: 'Type added successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(typeNotificationId, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};

export const updateTypeService = async (type, type_id) => {
    try {
        const body = {
            name: type.name,
            description: type.description,
            type_group_id: type.type_group_id,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/types/${type_id}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const deleteTypeService = async (type_id) => {
    const typeNotificationId = 'type-delete';

    toast.loading('Please wait...', { toastId: typeNotificationId });

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/types/${type_id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = await response.json();

            toast.update(typeNotificationId, {
                render: 'Type deleted successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(typeNotificationId, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};

export const getAllSubTypesService = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/sub-types/all`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const paginateSubTypesService = async (page, search = '') => {
    try {
        const size = 10;

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/settings/sub-types?search=${search}&size=${size}&page=${page}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addSubTypeService = async (subType) => {
    const subTypeNotificationId = 'sub-type-add';
    toast.loading('Please wait...', { toastId: subTypeNotificationId });

    try {
        const body = {
            name: subType.name,
            description: subType.description,
            type_id: subType.type_id,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/sub-types`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const data = await response.json();

            toast.update(subTypeNotificationId, {
                render: 'Sub type added successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(subTypeNotificationId, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};

export const updateSubTypeService = async (subType, sub_type_id) => {
    try {
        const body = {
            name: subType.name,
            description: subType.description,
            type_id: subType.type_id,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/sub-types/${sub_type_id}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const deleteSubTypeService = async (sub_type_id) => {
    const subTypeNotificationId = 'sub-type-delete';

    toast.loading('Please wait...', { toastId: subTypeNotificationId });

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/sub-types/${sub_type_id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = await response.json();

            toast.update(subTypeNotificationId, {
                render: 'Sub type deleted successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(subTypeNotificationId, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};
