import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';

import { UserProvider } from './context/UserContext';
import { ApiProvider } from './context/ApiContext';

import App from './app/App';

import './styles/index.scss';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ApiProvider>
                <BrowserRouter>
                    <UserProvider>
                        <App />
                    </UserProvider>
                </BrowserRouter>
            </ApiProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
