import {toast} from 'react-toastify';

import {getNotificationsSettings} from '../data/notificationsSettings';

export const getAllUsersService = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const paginateUsersService = async (page, search = '') => {
    try {
        const size = 10;
        const orderBy = {
            f_name: 'ASC',
            m_name: 'ASC',
            l_name: 'ASC',
        };

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/profiles?search=${search}&size=${size}&page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        const json = await response.json();

        return json;
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addUserService = async (user) => {
    const body = {
        email: user.email,
        username: user.username,
        role: user.role,
        f_name: user.f_name,
        m_name: user.m_name || 'null',
        l_name: user.l_name,
        dob: user.dob,
    };

    const notificationId = 'add-user-notification';

    const addUserNotification = toast.loading('Please wait...', {toastId: notificationId});

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/generate`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const data = await response.json();

            toast.update(addUserNotification, {
                render: 'User added successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return data;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(addUserNotification, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};

export const getCurrentUserService = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = await response.json();

            if (data.role !== 'User') {
                return data;
            } else {
                localStorage.removeItem('token');
                return {};
            }
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};
