import { createContext, useState } from 'react';

import {
    getAllTypeGroupsService,
    paginateTypeGroupsService,
    addTypeGroupService,
    updateTypeGroupService,
    deleteTypeGroupService,
    getAllTypesService,
    paginateTypesService,
    addTypeService,
    updateTypeService,
    deleteTypeService,
    getAllSubTypesService,
    paginateSubTypesService,
    addSubTypeService,
    updateSubTypeService,
    deleteSubTypeService,
} from '../services/settingsService';
import { loginService } from '../services/authService';
import { getAllUsersService, paginateUsersService, addUserService } from '../services/usersService';

const ApiContext = createContext({});

const ApiProvider = ({ children }) => {
    const [refetchCount, setRefetchCount] = useState(0);

    const apiState = {
        getAllTypeGroupsService,
        paginateTypeGroupsService,
        addTypeGroupService,
        updateTypeGroupService,
        deleteTypeGroupService,
        getAllTypesService,
        paginateTypesService,
        addTypeService,
        updateTypeService,
        deleteTypeService,
        getAllSubTypesService,
        paginateSubTypesService,
        addSubTypeService,
        updateSubTypeService,
        deleteSubTypeService,
        loginService,
        getAllUsersService,
        paginateUsersService,
        addUserService,
        refetchCount,
        setRefetchCount,
    };

    return <ApiContext.Provider value={apiState}>{children}</ApiContext.Provider>;
};

export { ApiContext, ApiProvider };
