import { Suspense, useContext, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

import Loader from '../../components/Loader/Loader';

const Login = lazy(() => import('../../views/Login/Login'));
const MainLayout = lazy(() => import('../../layouts/MainLayout/MainLayout'));
const UsersPage = lazy(() => import('../../views/UsersPage/UsersPage'));
const TypeGroupsPage = lazy(() => import('../../views/TypeGroups/TypeGroups'));
const TypesPage = lazy(() => import('../../views/TypesPage/TypesPage'));
const SubTypes = lazy(() => import('../../views/SubTypes/SubTypes'));

const MainRoutes = () => {
    const { isLoading } = useContext(UserContext);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<MainLayout />}>
                    <Route index path="users" element={<UsersPage />} />
                    <Route path="settings/groups" element={<TypeGroupsPage />} />
                    <Route path="settings/types" element={<TypesPage />} />
                    <Route path="settings/subTypes" element={<SubTypes />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default MainRoutes;
