import { toast } from 'react-toastify';

import { getNotificationsSettings } from '../data/notificationsSettings';

export const loginService = async (user) => {
    const body = {
        email: user.email,
        password: user.password,
    };

    const notificationId = 'login-notification';

    const loginNotification = toast.loading('Please wait...', { toastId: notificationId });

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const data = await response.json();

            if (data.profile.role !== 'User') {
                toast.update(loginNotification, {
                    render: 'Welcome Back!',
                    type: 'success',
                    isLoading: false,
                    ...getNotificationsSettings(),
                });

                return data;
            } else {
                toast.update(loginNotification, {
                    render: 'You are not authorized to login, please use your admin account!',
                    type: 'error',
                    isLoading: false,
                    ...getNotificationsSettings(),
                });

                return {};
            }
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(loginNotification, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};
