import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCurrentUserService } from '../services/usersService';

const UserContext = createContext({});

const UserProvider = ({ children }) => {
    const token = localStorage.getItem('token');

    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [search, setSearch] = useState('');

    const navigate = useNavigate();

    const userState = {
        isLoading,
        isLoggedIn,
        user,
        search,
        setIsLoading,
        setIsLoggedIn,
        setUser,
        setSearch,
    };

    useEffect(() => {
        const getCurrentUser = async () => {
            const response = await getCurrentUserService();

            if (response) {
                setUser(response);

                setIsLoading(false);
                setIsLoggedIn(true);
            }
        };

        if (token) {
            getCurrentUser().then(() => navigate('/dashboard/users'));
        } else {
            setIsLoading(false);
            navigate('/');
        }
    }, []);

    return <UserContext.Provider value={userState}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
